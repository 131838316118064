html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, input, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
  border: 0;
  box-sizing: border-box;
	font-size: 100%;
	font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
input {
	appearance: none;
}

.questionnaire-frame-container {
	position: relative;
	bottom: 50px;
	min-width: 50%;
	box-shadow: 0px 0px 23.9583px rgba(0, 0, 0, 0.11);
}
.questionnaire-frame {
	min-height: 75vh !important;
}
@media (max-width: 767px) {
	.questionnaire-frame-container {
		min-width: 75%;
	}
	.questionnaire-frame {
		min-height: 100vh;
	}
}
/*** END RESET ***/

/*** FONTS ***/
@font-face {
	font-family: 'Proxima Nova';
	src: local('Proxima Nova'), url('../assets/fonts/proxima_nova/ProximaNova-Regular.otf') format('opentype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: local('Proxima Nova'), url('../assets/fonts/proxima_nova/ProximaNova-AltBold.otf') format('opentype');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: local('Proxima Nova'), url('../assets/fonts/proxima_nova/ProximaNova-Bold.otf') format('opentype');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Proxima Nova';
	src: local('Proxima Nova'), url('../assets/fonts/proxima_nova/ProximaNova-Bold.otf') format('opentype');
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Heebo';
	src: url('../assets/fonts/heebo/Heebo-Regular.ttf') format('truetype');
	font-style: normal;
	font-weight: 400;
	font-display: swap;
}

@font-face {
	font-family: 'Heebo';
	src: url('../assets/fonts/heebo/Heebo-SemiBold.ttf') format('truetype');
	font-style: normal;
	font-weight: 600;
	font-display: swap;
}

@font-face {
	font-family: 'Heebo';
	src: url('../assets/fonts/heebo/Heebo-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: 700;
	font-display: swap;
}

@font-face {
	font-family: 'Heebo';
	src: url('../assets/fonts/heebo/Heebo-Bold.ttf') format('truetype');
	font-style: normal;
	font-weight: bold;
	font-display: swap;
}

@font-face {
	font-family: 'Verveine';
	src: local('Verveine'), 
	url('../assets/fonts/verveine/VerveineRegular.woff') format('woff'),
	url('../assets/fonts/verveine/VerveineRegular.ttf') format('truetype');
	font-display: swap;
}